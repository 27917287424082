import React from 'react'
import './homePage.css'
import '../../styleguide.css'
import logoBlackAndWhite from '../../../images/logoBlackAndWhite.png'
import introPhoto from '../../../images/introPhoto.jpg'
import portrait from '../../../images/portrait.jpg'
import plante from '../../../images/icons/plante.svg'
import ear from '../../../images/icons/pict_ear.svg'
import juriste from '../../../images/icons/pict_juriste.svg'
import lampe from '../../../images/icons/Lampe.svg'
import divider from '../../../images/icons/divider.svg'
import Slider from '../../slider/slider'
import Contact from '../contact/contact'

class HomeTest extends React.PureComponent {
  renderIntroBloc() {
    return (
      <div className="intro-bloc-container"> 
        <img src={logoBlackAndWhite} className="intro-logo" />
        <div className="intro-content">
          <div className="intro-heading">
            <div className="intro-heading-title">
              <span className="intro-heading-title--big">Clara</span> König
            </div>
            <div className="intro-heading-subtitle">Mediation familiale</div>
          </div>
          <div className="intro-text">
            <div className="intro-text-heading">Bienvenue, </div>
            <div>
              et félicitations ! Vous venez de poser le premier pied sur le pont
              du processus qui mène du conflit à la sérénité.
            </div>
            <div className="intro-text-signature">Clara</div>
          </div>
        </div>
        {
        <img src={plante} className="plante" />
        }
      </div>
    )
  }

  renderPhotoBloc(photo) {
    return <img src={photo} className="photo" />
  }

  renderSeparator() {
    return (
      <div className="separator-container">
        <span className="separator separator-long" />
        <span className="separator-empty" />
        <span className="separator separator-short" />
      </div>
    )
  }
  renderCitationBloc() {
    return (
      <div className="citation-container">
        <div className="citation-content">
          <div className="citation-text">
            La médiation vise à rétablir le dialogue entre des personnes en
            situation de rupture de communication.
          </div>
          {this.renderSeparator()}
        </div>
      </div>
    )
  }
  renderPresentation() { 
    return (
      <div className="presentation-container">
        <div className="presentation-heading">Qui suis-je ?</div>
        <img src={divider} className="presentation-heading-divider" />

        <div className="presentation-content">
          <div className="presentation-block">
            <div className="presentation-text presentation-para-container">
              <p>
                Lors d’actions accomplies en tant que bénévole, j’ai constaté
                combien des malentendus pouvaient vite surgir et s’amplifier et
                que mon intervention comme tierce personne permettait de les
                désamorcer par une écoute appropriée.
              </p>
              <p>
                Cette expérience, ainsi que mon attirance pour la résolution
                amiable des conflits dans un cadre d’écoute bienveillante m’ont
                amenée à une profonde réflexion sur mes aspirations
                professionnelles.
              </p>

              <p>
                Bien que spécialisée dans les relations familiales, je pratique
                également la médiation et le conseil en gestion de conflits dans
                toutes les situations de conflits interpersonnels, car les
                moyens mis en œuvre sont les mêmes.
              </p>
            </div>
          </div>
          <div className="presentation-block">
            <div className="presentation-item">
              <img src={juriste} className="presentation-item-icon" />
              <div>
                <div className="presentation-item-title">JURISTE</div>
                <div className="presentation-text">
                  Maîtrise en Droit de l’Université de Nice <br />
                  Diplôme d’Etudes Supérieures de l’Université de Genève.
                </div>
              </div>
            </div>
            <div className="presentation-item">
              <img src={ear} className="presentation-item-icon" />
              <div>
                <div className="presentation-item-title">
                  Médiatrice familiale
                </div>
                <div className="presentation-text">
                  Diplôme d’Etat de médiatrice familiale
                  <br />
                  Formation aux techniques d’écoute, <br />
                  Programmation neurolinguistique (PNL),
                  <br />
                  Pocess-communication et communication non violente (CNV).{' '}
                  <br />
                </div>
              </div>
            </div>
            <div className="presentation-item">
              <img src={juriste} className="presentation-item-icon" />
              <div>
                <div className="presentation-item-title">Associatif</div>
                <div className="presentation-text">
                  Membre de l’Association internationale francophone des
                  intervenants auprès des familles séparées (AIFI).
                  <br />
                  Membre de l’Association pour la médiation familiale (APMF){' '}
                  <br />
                  Membre la Fédération nationale de la médiation et des espaces
                  de rencontre (Fenamef).
                </div>
              </div>
            </div>
          </div>
        </div>
        <img src={lampe} className="lampe" />
      </div>
    )
  }

  render() {
    return (
      <div className={'fill-container home-container'}>
        <div className="intro-container">
          {this.renderIntroBloc()}
          <div className="intro-photo-container">
            {this.renderPhotoBloc(introPhoto)}
          </div>
        </div>
        {this.renderCitationBloc()}
        <div className="intro-container">
          <div className="mediation-photo-container">
            {this.renderPhotoBloc(portrait)}
          </div>
          <div className="mediation-slider-container">
            <Slider />
          </div>
        </div>
        {this.renderPresentation()}

    {
        <Contact />
    }
       
      </div>
    )
  }
}

export default HomeTest
