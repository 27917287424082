import React from 'react'
import './contact.css'

const Contact = () => (
  <div className={'contact-container contact-full-screen container-padding'}>
    <div className={'contact-line contact-line-top'} />
    <div className={'contact-container'}>
      <div className={'contact-container contact-section'}>
        <h3>contact</h3>
        <a href={'/#presentation'}>Clara König</a>
      </div>
      <div className={'contact-container contact-section'}>
        <h3>téléphone</h3>
        <a href="tel:+41794094397" className="contact-item">
          ch: + 41 (0)79 409 43 97
        </a>
        <a href="tel:+33645701340" className="contact-item">
          fr: + 33 (0)6 45 70 13 40
        </a>
      </div>
      <div className={'contact-container contact-section'}>
        <h3>mail</h3>
        <a href="mailto:mediation.pdg@orange.fr" className="contact-item">
          ch: mediation.pdg@orange.fr
        </a>
        <a href="mailto:mediation.pdg@orange.fr" className="contact-item">
          fr: clara.konig@bluewin.ch
        </a>
      </div>
      <div className={'contact-container contact-section'}>
        <h3>adresse</h3>
        <span className={'contact-info contact-item'}>
          ch: Chemin du Martinet 9 CH-1263 Crassier
        </span>
        <span className={'contact-info contact-item'}>
          fr: 557 Avenue de Genève, 01220 Divonne-les-Bains
        </span>
      </div>
    </div>

    <div className={'contact-line contact-line-bottom'} />
  </div>
)

export default Contact
