import React from 'react'
import './slider.css'

const paraArrays = [
  [
    'Le médiateur les accueille dans un cadre bienveillant qui réhabilite le respect, l’écoute et la compréhension des besoins de l’autre, afin de leur permettre de construire conjointement une solution « sur mesure » adaptée à leur situation particulière et mutuellement acceptable.',
    'Le médiateur est un tiers, neutre, impartial et indépendant, ce qui permet aux personnes qu’il accompagne de sortir de l’aspect binaire et destructeur de leur conflit, pour faire émerger un dialogue constructif et positif pour elles.',
    'Dans le domaine familial, il est en mesure d’accompagner toutes les personnes confrontées à un problème en lien avec la famille.',
  ],
  [
    'Il peut s’agir par exemple de couples séparés ou envisageant éventuellement de se séparer, de familles recomposées, de parents ou grands-parents souhaitant renouer la communication avec leurs descendants, de parents ayant du mal à communiquer avec leur enfant adolescent ou encore de fratries adultes confrontées à des différends dans des situations de vieillissement de leurs parents ou de succession litigieuse…',
  ],
  [
    'Le médiateur familial est un professionnel spécifiquement formé dans les domaines de la psychologie, la sociologie et du droit. Il est donc particulièrement attentif au fait que   chaque famille représente une structure unique et complexe dans les dimensions psychologique, culturelle, affective, sociologique, économique et légale. Cela permet de créer un climat de confiance propice à l‘écoute, l’échange, la réflexion et un processus de cheminement vers des solutions au conflit, qu’il porte sur des valeurs éducatives, la place de chacun dans la famille, des souvenirs douloureux et/ou des questions concrètes et pragmatiques.',
  ],
  [
    'D’un point de vue plus général, le médiateur est aussi en mesure d’accompagner les personnes vers la résolution amiable de conflits se manifestant par exemple dans des relations de voisinage, d’ordre contractuel, dans le domaine professionnel et de l’entreprise.',
  ],
]
class Slider extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      stepNumber: 0,
    }
  }

  renderText(paraArray) {
    return (
      <div>
        {paraArray.length !== 0 && paraArray.map(para => <p>{para}</p>)}
      </div>
    )
  }
  render() {
    const { stepNumber } = this.state
    return (
      <div className="slider-container">
        <div className="slider-heading">Le médiateur</div>
        <div className="slider-content">
          {paraArrays[stepNumber] && this.renderText(paraArrays[stepNumber])}
        </div>
        <div className="slider-stepper-container">
          {paraArrays.length !== 0 &&
            paraArrays.map((para, i) => (
              <div
                className="slider-stepper"
                style={i === stepNumber ? { opacity: 1 } : {}}
                onClick={() => this.setState({ stepNumber: i })}
              />
            ))}
        </div>
      </div>
    )
  }
}

export default Slider
